@import 'variables';

.dialog-panel-styles .mat-mdc-dialog-container,
.overflow-dialog-panel-styles .mat-mdc-dialog-container,
.custom-dialog-panel .mat-mdc-dialog-container {
    padding-bottom: 5px;

    >* {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .mat-mdc-dialog-title {
            color: var(--primary-color);
            padding-top: 24px;

            &:before {
                content: none;
            }
        }

        .mat-mdc-dialog-actions {
            display: flex;
            justify-content: flex-end !important;
            flex-wrap: wrap;
            margin-bottom: 0;
        }
    }
}

.dialog-panel-styles .mat-mdc-dialog-container,
.overflow-dialog-panel-styles .mat-mdc-dialog-container {
    width: 100%;
    height: 100%;
    min-height: 150px;
    max-height: 600px;
    min-width: 500px;
    max-width: 900px;
}

.overflow-dialog-panel-styles .mat-mdc-dialog-container {
    overflow: visible;
}