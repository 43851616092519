@import 'variables';

.custom-table {
    position: relative;
    overflow-x: auto;
    $self: &;

    &>table {
        width: 100%;

        &>tbody {
            &>tr {
                &:nth-child(odd) {
                    background-color: var(--table-row-odd);
                }

                &:not(.nohover):hover {
                    background-color: var(--table-row-hover);
                }

                &.clickable:hover {
                    cursor: pointer;
                }

                #{$self}__edit:hover {
                    color: var(--primary-color);
                    background-color: var(--light-accent);
                }

                #{$self}__delete:hover {
                    color: var(--tsr-accent);
                    background-color: var(--tsr-light-accent);
                }
            }
        }
    }
}

.dark-table {
    position: relative;
    overflow-x: auto;

    table {
        width: 100%;

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: $grey-100;
                }

                background-color: $grey-100;

                &:hover {
                    background-color: $grey-200;
                }

                &.clickable:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.row-office-type-1 {
    &:hover {
        background-color: var(--tsr-table-row-hover) !important;
    }
}