//fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

//libraries
@import "ngx-toastr/toastr";
@import "bootstrap/utilities";
@import "material/theme";
@import "@ng-select/ng-select/themes/material.theme.css";

//custom
@import "variables";
@import "main";
@import "table";
@import "form";
@import "button";
@import "toast";
@import "dialogs";
@import "tabs";
@import "timepicker";
@import "select";
@import "tooltip";