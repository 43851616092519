@import "variables";

* {
    box-sizing: border-box;
}

html,
body {
    background-color: var(--background) !important;
}

body {
    margin: 0;
    font-family: $font-family;
    position: relative;
    color: $text-color;
}

.margin {
    &-top {
        &-25 {
            margin-top: 25px;
        }

        &-100 {
            margin-top: 100px;
        }
    }
}

.full-width-input {
    width: 100%;
}

.small-info-page {
    padding: 15px 1rem;

    mat-card {
        max-width: 400px;
        margin: 2em auto;
        text-align: center;
    }
}

.big-info-page {
    padding: 15px 1rem;

    mat-card {
        max-width: 700px;
        margin: 2em auto;
        text-align: center;
    }
}

.float-right {
    float: right;
}

.flex-display-inline {
    display: flex;
}

.hide-bottom-scroll {
    overflow-x: hidden;
}

.mat-mdc-selection-scroll {
    max-height: 330px;
    overflow: auto;
}

.card-container {
    background: #454b50;
    border: solid 1px #454b50;
    color: white;
    width: 100%;
    display: flex;
}

.title-card-container {
    background: var(--dark-grey);
    color: white;
    padding: 10px 15px;
    display: flex;
    align-items: center;
}

.card-list-container {
    background: #e8e8e8;
    width: 100%;
    display: flex;
}

.card-detail-content {
    padding-bottom: 80px;
}

.customer-headers-components {
    font-weight: bold;
    font-size: 11px;
}

.customer-label-components {
    font-size: 10px;
}

.list-header-style {
    font-weight: bold;
    font-size: 14px;
}

.panel-title-style {
    font-weight: bold;
}

.cursor-pointer {
    cursor: pointer;
}

.divider-margin {
    margin-top: 15px !important;
}

mat-card.mat-mdc-card {
    height: 100%;
    padding: 0px;

    mat-card-title {
        &.title {
            border-bottom: 2px solid var(--primary-color);
        }

        .header-title {
            color: var(--primary-color);
            padding: 20px;
            margin: 0;
        }

        .navigate-icon {
            position: absolute;
            right: 20px;
            top: 20px;
            color: var(--primary-color);
            cursor: pointer;
        }
    }

    mat-card-content {
        padding: 24px;
    }
}

.mdc-dialog__title+.mdc-dialog__content,
.mdc-dialog__header+.mdc-dialog__content {
    padding-top: 4px !important;
}

ngx-material-timepicker-toggle {
    display: block;
    padding-right: 12px;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
    padding: 0 !important;

    &:first-of-type {
        padding-left: 24px !important;
    }
}