//media-screen
$x-large-screen: 1339px;
$large-screen: 1199px;
$medium-screen: 991px;
$small-screen: 767px;
$x-small-screen: 575px;

$x-large-screen-up: 1340px;
$large-screen-up: 1200px;
$medium-screen-up: 992px;
$small-screen-up: 768px;
$x-small-screen-up: 576px;

//in case of small content change these values to higher one
$container-max-widths: (sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1300px);
$grid-breakpoints: (xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1340px);

//dynamic colors
:root {
    //tsr
    --tsr-primary-color: #EE7012;
    --tsr-secondary-color: #ffffff;
    --tsr-dark-accent: #c55a07;
    --tsr-accent: #D64545;
    --tsr-accent-50: #9f3248ec;
    --tsr-accent-100: #831f2f;
    --tsr-light-accent: #fff0f0;
    --tsr-table-row-odd: #fbfbf9;
    --tsr-table-row-hover: #fffafa;
    --tsr-table-row: #fff0f0;
    --tsr-background: #fafafa;

    --primary-color: var(--tsr-primary-color);
    --secondary-color: var(--tsr-secondary-color);
    --dark-accent: var(--tsr-dark-accent);
    --accent: var(--tsr-accent);
    --accent-100: var(--tsr-accent-100);
    --light-accent: var(--tsr-light-accent);
    --table-row-odd: var(--tsr-table-row-odd);
    --table-row-hover: var(--tsr-table-row-hover);
    --background: var(--tsr-background);
}

//static colors
$dark-yellow: #736a08;
$yellow: #F7C948;
$light-yellow: #fff7b0;
$dark-blue: #084a73;
$blue: #40C3F7;
$light-blue: #b0ebff;
$red: #D64545;
$dark-red: #490606;
$light-red: #ffbdbd;
$dark-purple: #1f0b43;
$purple: #724BB7;
$light-purple: #ccb9ee;
$dark-green: #073d32;
$green: #57da40;
$light-green: #d3f4c4;
$grey-600: #625D52;
$grey-500: #857F72;
$grey: #A39E93;
$grey-300: #B8B2A7;
$grey-200: #D3CEC4;
$grey-100: #E8E6E1;
$light-grey: #f9f9f6;

//fonts
$font-family: "Roboto",
sans-serif;
$text-color: rgb(0, 0, 0);
$disabled-font-color: #17306799;