@import "variables";

ngx-material-timepicker-container {
    .timepicker-backdrop-overlay {
        z-index: 2000 !important;
    }

    .timepicker-overlay {
        z-index: 2000 !important;
    }
}

ngx-material-timepicker-content {
    --button-color: var(--primary-color);
    --dial-background-color: var(--primary-color);
    --dial-editable-active-color: var(--primary-color);
    --clock-hand-color: var(--primary-color);
}

ngx-material-timepicker-toggle {
    button svg path {
        fill: #757575;
    }
}