@import "variables";

div.mat-mdc-tab-nav-bar {
    .mat-mdc-tab-links {
        background: #ffffff;

        a {

            &.navbar__nav-item--active,
            &:hover {
                color: var(--primary-color);
                font-weight: 500;
                opacity: 1;
                background: rgba(0, 0, 0, .04);
            }
        }
    }
}