@import 'variables';

button {
    mat-icon {
        margin-right: 5px;
    }

    &[mat-icon-button] {
        &:disabled {
            cursor: not-allowed;
        }

        &.dual {
            color: $grey;

            &:hover {
                background-color: $light-grey;
                color: var(--primary-color);
                ;
            }
        }

        &[color="primary"]:hover {
            background: rgba(var(--primary-color), 0.04);
        }

        &[color="accent"]:hover {
            background: $light-grey;
        }
    }

    &.report-btn {
        height: 36px;
        margin-right: 20px;
    }
}